import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'

import { Button } from '@paljs/ui/Button'
import { InputGroup } from '@paljs/ui/Input'
import Alert from '@paljs/ui/Alert'

import Auth, { Group } from '../../components/Auth'
import Loading from '../../components/Loading'

import useAuth from '../../util/useAuth'

import * as api from '../../util/api'

const RequestPassword: React.FC = () => {
  const { getUser } = useAuth()

  const [error, setError] = useState<string>('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getUser()
      .then(() => navigate('/'))
      .catch(() => setLoading(false))
  }, [])

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    setError('')

    const email: string = event.target['email'].value

    try {
      await api.forgot(email)
      navigate('/auth/email-sent')
    } catch (e) {
      const error = api.getStrapiError(e)
      setError(error)
    }
  }

  if (loading) return <Loading isAuth />
  return (
    <Auth title='Forgot Password' subTitle='Enter your email address and we’ll send a link to reset your password'>
      <form method='post' onSubmit={handleSubmit}>
        <InputGroup fullWidth>
          <input type='email' name='email' placeholder='Email Address' />
        </InputGroup>
        <Button status='Success' type='submit' shape='SemiRound' fullWidth>
          Request Password
        </Button>
        {!!error && (
          <Alert closable status='Danger' onClose={() => setError('')}>
            {error}
          </Alert>
        )}
      </form>
      <Group>
        <Link to='/auth/login'>Back to Log In</Link>
      </Group>
    </Auth>
  )
}
export default RequestPassword
